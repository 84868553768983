<template>
  <div class="c-customer-config-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag">
      <section>
        <el-form ref="editItem" label-width="100px">
          
          <znl-input
            v-model="editItem.CustomerLevelName"
            border
            class="znl-input"
            placeholder=""
            title="客户等级: "
            title-width="70px"
            :disabled="true"
            width="250px">
           
          </znl-input>
          <znl-input
            v-model="editItem.CustomerMinSection"
            border
            class="znl-input"
            placeholder=""
            title="最小区间: "
            title-width="70px"
            width="250px">

          </znl-input>
          
          <znl-input
            v-model="editItem.CustomerMaxSection"
            border
            class="znl-input"
            placeholder=""
            title="最大区间: "
            title-width="70px"
            width="250px">

          </znl-input>
          <el-form-item style="text-align: right">
            <el-button type="primary" @click="onSave" :style="{ display: visibleLine }">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
          </el-form-item>

        </el-form>
      </section>

    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from '@/directive/el-drag-dialog' // base on element-ui
import {
AduitCustomerLevel
} from "@/api/client";

export default {
  name: 'CustomerConfigEdit',
  directives: {
    elDragDialog
  },
  data() {
    return {
     
      visible: false,
      title: '新增等级',
      editItem:{
        Id:0,
        CustomerLevelName:"",
        CustomerMinSection:null,
        CustomerMaxSection:null,
      },
    }
  },
  methods: {
    onOpen(data) {
    console.log(data)
    
    if (data === null) {
        Object.assign(this.$data.editItem, this.$options.data().editItem);
      } else {
        Object.keys(this.editItem).forEach((key) => {
          this.editItem[key] = data[key];
        });
      }
      this.visible = true
    },
    async onSave()
    { 
      console.log(this.editItem.CustomerMinSection)
      console.log(this.editItem.CustomerMaxSection)
      console.log(this.editItem.Id)
     
      var CustomerMinSection=parseInt(this.editItem.CustomerMinSection)
      var CustomerMaxSection=parseInt(this.editItem.CustomerMaxSection)
      if(CustomerMinSection>CustomerMaxSection)
      {

        this.$message.warning("最小区间不可大于最大区间")
         throw new Error('End')
      }
         await AduitCustomerLevel(this.editItem)
         this.$message.success('编辑成功')
         this.$emit('onSearch')
         this.visible = false
        this.isEdit=false
    
    }
    ,
    handleDrag() {

    }
  },
  created() {

  }
}
</script>
<style lang="scss">
.c-task-config-view-dialog {
  .el-dialog {
    max-width: 500px !important;
    min-width: 400px !important;
  }
}

</style>

