<template>
  <div class="m-grid p-CustomerConfig-client">
    <section class="m-grid-search">
      <znl-input
        border
        width="150px"
        placeholder="搜索等级"
        clearable
        class="m-margin--right-10"
        v-model="searchData.Name"
      >
      </znl-input>
      <el-button class="m-grid-btn" type="primary" @click="onSearch()"
        >查询</el-button
      >
    </section>
    <section class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.size"
        :selection="false"
        :total-count="pagination.total"
        headerHeight="35"
        type="base"
      >
      </grid>
    </section>
    <customer-Config-edit
      ref="editView"
       @onSearch="onSearch"
    ></customer-Config-edit>

  </div>
</template>
<script>

import { columns } from "./Modules/CustomerConfig/config";
import {
GetCustomerLevel
} from "@/api/client";
import CustomerConfigEdit from "./Modules/CustomerConfig/CustomerConfigEdit";
export default {
  name: 'CustomerConfigView',
  components: {
    CustomerConfigEdit
  },
  data() {
    return {
      itemSource: [],
      columns: columns,
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      searchData: {
        Name: ''
      },
      isEdit: false,
    }
  },
  methods: {
    async onSearch(page = 1) {
      const {pagination, searchData} = this;
      const sendData = {
        Page: page,
        Limit: pagination.size,
        LevalName:searchData.Name
      };

      Object.keys(searchData).forEach(key => {
        sendData[key] = searchData[key];
      });

      const data = await GetCustomerLevel(sendData)

      pagination.page = data.Page;
      pagination.total = data.Total;
      const items = data['Items'];


      if (items.length) {
        items.forEach((item, index) => {
          item.RowIndex = index + 1;
          item.CreatedTime = this.formatDate(item.CreatedTime, 'yyyy-MM-dd hh:mm')
          item.UpdatedTime = this.formatDate(item.UpdatedTime, 'yyyy-MM-dd hh:mm')
        });
      }
      this.itemSource = items;
    },
     // 初始化表格
    initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");
     

        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };


      this.$refs.list.changeColumns(columns);
    },

    setButtons(params) {
      let data = params.data;
      console.log(data)
      
      let buttons = [];
      let self = this;
  
        const auditBtn = {
        text: "编辑",
        click() {
          self.onAudit(data);
        },
        }
      
      buttons.push(auditBtn)

      return buttons;
      },
     async onAudit(data) {
      console.log(data)


     this.isEdit=true
        const editItem={
          Id:data.Id,
          CustomerMinSection:data.CustomerMinSection,
          CustomerLevelName :data.CustomerLevelName,
          CustomerMaxSection:data.CustomerMaxSection
        };

        this.$refs.editView.onOpen(editItem);
  },
    },

  created() {
    this.onSearch()
  },
  mounted() {
    this.initColumns()
  }
}
</script>
