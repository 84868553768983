const baseInfoList = [
  {
    placeholder: '客户编码',
    binding: 'CompanyCode',
  },
  {
    placeholder: '客户名称',
    binding: 'CompanyName',
  },
  {
    placeholder: '固定电话',
    binding: 'Phone',

  },
  {
    placeholder: '公司传真',
    binding: 'Fax',

  },
  {
    placeholder: '公司地址(省)',
    binding: 'Province',
  },
  {
    placeholder: '公司地址(市)',
    binding: 'City',

  },
  {
    placeholder: '公司地址(区)',
    binding: 'Area',
  },
  {
    placeholder: '公司地址(详细)',
    binding: 'Address',
  },
  {
    placeholder: '联系人手机号',
    binding: 'Mobile',
  },
  {
    placeholder: '联系人微信号',
    binding: 'WeChat',
  },
  {
    placeholder: '联系人QQ',
    binding: 'QQ',
  },
  {
    placeholder: '联系人旺旺',
    binding: 'WangWang',
  },
  {
    placeholder: '业务员QQ',
    binding: 'ConnectQQ',
  },
  {
    placeholder: '业务员微信',
    binding: 'ConnectWeChat',
  },
  {
    placeholder: '业务员旺旺',
    binding: 'ConnectWangWang',
  }
]

// 属性列头配置
export const ClientOptions = [
  {key: 'AddInquiry', value: '新增询价客户'},
  {key: 'Commonly', value: '一般客户'},
  {key: 'StressFollow', value: '重点跟进客户'},
  {key: 'StressProtect', value: '重点保护客户'},
  {key: 'Assist', value: '协助客户'},
  {key: 'Subordinate', value: '下属客户'}
]
// 列头配置
const columns = [
	{
		field: 'rowIndex',
		headerName: '序号',
		width: 70,
		cellClass: 't-c',
		valueFormatter: ({ node: { rowIndex } }) => {
			return rowIndex + 1;
		}
	},
  {
    field: 'CompanyName',
    headerName: '客户名称',
    editable: false,
    width: 120,
    cellClass: 't-l',
  },
  {
    field: 'EmergencyLevel',
    headerName: '紧急度',
    editable: false,
    width: 90,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellRateRender'
  },
  {
    field: 'CooperationLevel',
    headerName: '合作等级',
    editable: false,
    width: 100,
    cellClass: 't-c'
  },
  {
    field: 'AverageP',
    headerName: '平均分',
    editable: false,
    width: 90,
    cellClass: 't-c',
  },
  {
    field: 'NoOrderCountdownP',
    headerName: '未下单公海倒计时',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 150,
    cellClass: 't-c',
  },
  {
    field: 'NoInquiredCountdownP',
    headerName: '未询价公海倒计时',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 150,
    cellClass: 't-c',
  },
  {
    field: 'NofollowCountdownP',
    headerName: '未跟进公海倒计时',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 150,
    cellClass: 't-c',
  },
  {
    field: 'ScoreBaseInfo',
    headerName: '基础资料分',
    headerComponentFramework:"GridHenderSortDom2",
    editable: false,
    width: 100,
    cellClass: 't-c'
  },
  {
    field: 'ScoreAttribute',
    headerName: '属性资料分',
    editable: false,
    headerComponentFramework:"GridHenderSortDom2",
    width: 100,
    cellClass: 't-c'
  },
  {
    field: 'ScoreLabel',
    headerName: '标签资料分',
    headerComponentFramework:"GridHenderSortDom2",
    editable: false,
    width: 100,
    cellClass: 't-c'
  },
  {
    field: 'AttachEmpNames',
    headerName: '归属人',
    editable: false,
    width: 100,
    cellClass: 't-c'
  },
  {
    field: 'CreatedTime',
    headerName: '创建时间',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 150,
    cellClass: 't-c'
  },
  {
    field: 'FollowUpStatus',
    headerName: '跟进状态',
    editable: false,

    width: 100,
    cellClass: 't-c',
  },
  {
    field: 'LastFolowUpTime',
    headerName: '跟进时间',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 100,
    cellClass: 't-c'
  },
  {
    field: 'LastOrderTime',
    headerName: '最近下单时间',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 130,
    cellClass: 't-c'
  },
  {
    field: 'LastInquireTime',
    headerName: '最近询价时间',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 130,
    cellClass: 't-c'
  },
  {
    field: 'NoOrderDaysP',
    headerName: '未下单天数',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 90,
    cellClass: 't-c'
  },
  {
    field: 'NoInquiryDaysP',
    headerName: '未询价天数',
    headerComponentFramework: 'GridHenderSortDom',
    editable: false,
    width: 90,
    cellClass: 't-c'
  },
  // {
  //   field: 'CustomerType',
  //   headerName: '客户类型',
  //   editable: false,
  //   width: 100,
  //   cellClass: 't-c',
  //   valueFormatter(params) {
  //     const value = params.value
  //     let content = ''
  //     const item = ClientOptions.find(item => item.key === value)
  //     if (item) {
  //       content = item.value || ''
  //     }
  //     return content
  //   },
  // },
  {
    field: 'FollowUpContent',
    headerName: '最近跟进内容',
    editable: false,

    cellClass: 't-c'
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 130,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
    pinned: 'right'
  }
]

// 合并客户
const mergeClientColumns = [
  {
    field: 'isAllCheck',
    headerName: '',
    width: 50,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellRadioRender',
  },

  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c'
  },
  {
    field: 'CompanyName',
    headerName: '客户名称',
    width: 150,
    cellClass: 't-l',
    cellRendererFramework: 'GridCellSelectTitle',
    checkboxSelection: false
  },
  {
    field: 'Phone',
    headerName: '固定电话',
    width: 90,
    cellClass: 't-l',
    cellRendererFramework: 'GridCellSelectTitle'
  },
  {
    field: 'Fax',
    headerName: '传真',
    width: 90,
    cellClass: 't-l',
    cellRendererFramework: 'GridCellSelectTitle'
  },
  {
    field: 'FullAddress',
    headerName: '客户地址',
    width: 150,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellSelectTitle'
  },
  {
    field: 'ReceivingAddress',
    headerName: '收货地址',
    width: 150,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellSelectTitle'
  },
  {
    field: 'AttributeMuster',
    headerName: '客户属性',
    cellClass: 't-c',
    cellRendererFramework: 'GridCellSelectTitle'
  },
  {
    field: 'LicenseMuster',
    headerName: '营业执照信息',
    cellClass: 't-c',
    cellRendererFramework: 'GridCellSelectTitle'
  },
  {
    field: 'InvoicingMuster',
    headerName: '开票信息',
    cellClass: 't-c',
    cellRendererFramework: 'GridCellSelectTitle'
  },
  {
    field: 'SettlementMuster',
    headerName: '结算信息',
    cellClass: 't-c',
    cellRendererFramework: 'GridCellSelectTitle'
  },
]

export {
  columns,
  baseInfoList,
  mergeClientColumns
}

