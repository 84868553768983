import {ClientOptions} from "../List/config";

 const columns = [
  {
    field: 'RowIndex',
    headerName: '序号',
    editable: false,
    width: 55,
    cellClass: 't-c',
  },
  {
    field: 'CustomerLevelName',
    headerName: '合作等级',
    // width: 200,
    cellClass: 't-l'
  },
  {
    field: 'CustomerMinSection',
    headerName: '最小区间',
    // width: 200,
    cellClass: 't-l'
  },
  {
    field: 'CustomerMaxSection',
    headerName: '最大区间',
    // width: 200,
    cellClass: 't-l'
  },
  {
    field: 'CreatedTime',
    headerName: '创建时间',
    // width: 200,
    cellClass: 't-c'
  },
  {
    field: 'CreatedEmpName',
    headerName: '创建人',
    // width: 200,
    cellClass: 't-c'
  },
  {
    field: 'UpdatedTime',
    headerName: '修改时间',
    // width: 200,
    cellClass: 't-c'
  },
  {
    field: 'UpdatedEmpName',
    headerName: '修改人',
    // width: 200,
    cellClass: 't-c',
  }, 
  
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
export {
    columns
  }
  